import React, { useEffect, useState } from "react";
import classes from "./MobileVersion.module.css";

const MobileVersion = () => {
  // const [width, setWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   const updateConstraints = () => {
  //     setWidth(window.innerWidth);
  //   };

  //   updateConstraints();
  //   window.addEventListener("resize", updateConstraints);

  //   return () => {
  //     window.removeEventListener("resize", updateConstraints);
  //   };
  // }, []);

  return (
    <>
        <div className={classes.iframe_container}>
          <div className={classes.frame}>
            <iframe
              src="https://www.askme.visiontrek.io/"
              title="Visiontrek AI"
              className={classes.iframe}
              frameborder="0"
            ></iframe>
            <img
              src="/assets/images/iphone15.png"
              alt="phone"
              className={classes.phone}
            />
            <img
              src="/assets/images/notch.png"
              alt="phone"
              className={classes.notch}
            />
          </div>
        </div>
      {/* {width > 400 ? (
        <div className={classes.iframe_container}>
          <div className={classes.frame}>
            <iframe
              src="https://www.askme.visiontrek.io/"
              title="Visiontrek AI"
              className={classes.iframe}
              frameborder="0"
            ></iframe>
            <img
              src="/assets/images/iphone15.png"
              alt="phone"
              className={classes.phone}
            />
            <img
              src="/assets/images/notch.png"
              alt="phone"
              className={classes.notch}
            />
          </div>
        </div>
      ) : (
        <div className={classes.iframe_container_mobile}>
          <iframe
            src="https://www.askme.visiontrek.io/"
            title="Visiontrek AI"
            className={classes.iframe_mobile}
            frameborder="0"
          ></iframe>
        </div>
      )} */}
    </>
  );
};

export default MobileVersion;
