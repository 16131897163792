import React, { useEffect } from "react";
import classes from "./Drawer.module.css";
import SmallLoader from "./Loaders/SmallLoader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { closeDrawer } from "../slices/drawerSlice";
import { motion, useAnimation } from "framer-motion";

const Drawer = ({ chatHistoryLoading, chatHistory, chat_id, drawer }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateHandler = (route) => {
    dispatch(closeDrawer());
    navigate(route);
  };

  const controls = useAnimation();

  const animationVariants = {
    hidden: {
      display: "none",
      opacity: 0,
      x: -1000,
    },
    visible: {
      display: "block",
      opacity: 1,
      x: 0,
    },
  };

  useEffect(() => {
    if (drawer) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [drawer]);
  return (
    <motion.div
      className={classes.drawer}
      variants={animationVariants}
      initial="hidden"
      animate={controls}
      transition={{
        duration: 0.3,
        ease: "linear",
      }}
      onClick={() => dispatch(closeDrawer())}
    >
      <div className={classes.drawer_sub_container}>
        <div className={classes.logo_container}>
          <img
            src="/assets/images/ai-logo.png"
            alt="visiontrek"
            className={classes.logo}
          />
          <p className={classes.brand_name}>Visiontrek AI</p>
        </div>

        <div className={classes.drawer_content}>
          <button
            className={classes.new_chat_btn}
            type="button"
            onClick={() => navigateHandler("/app")}
          >
            <i className={`pi pi-plus ${classes.plus_icon}`}></i>
            <p className={classes.text}>New Chat</p>
          </button>

          <div className={classes.line_breaker}></div>

          {chatHistoryLoading ? (
            <SmallLoader />
          ) : (
            <>
              {chatHistory?.map((chat, idx) => {
                return (
                  <button
                    className={`${classes.chat_box} ${
                      chat_id == chat?.chat_id && classes.active
                    }`}
                    type="button"
                    key={idx}
                    onClick={() => navigateHandler(`/app/${chat?.chat_id}`)}
                  >
                    <i
                      className={`pi pi-comment ${classes.chat_icon}`}
                    ></i>
                    <p className={classes.chat_box_text}>{chat?.chat_name}</p>
                  </button>
                );
              })}

              {/* <button type="button" className={classes.faq_btn}>
                <i
                  className={`fa-solid fa-circle-question ${classes.faq_icon}`}
                ></i>
                <p className={classes.text}>FAQ</p>
              </button> */}
              <button type="button" className={classes.recent_btn} onClick={()=>navigate("/recent")}>
                <i className={`pi pi-clock ${classes.recent_icon}`}></i>
                <p className={classes.text}>Recent</p>
              </button>

              {/* <div className={classes.recent_btn}>
                <p className={classes.text}>Terms & Conditions</p>
              </div> */}
            </>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default Drawer;
