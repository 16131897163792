import React, { useEffect, useRef, useState } from "react";
import classes from "./ChatBox.module.css";
import DotLoader from "../Loaders/DotLoader";
import { motion } from "framer-motion";
import FallbackIframe from "./FallbackIframe";

const ChatBox = ({
  messages,
  aiChatLoading,
  chatLoading,
  showCategories,
  info,
  style
}) => {
  const chatRef = useRef(null);

  useEffect(() => {
    if (!chatLoading && chatRef?.current) {
      chatRef?.current.scrollTo({
        top: chatRef?.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [chatRef, chatLoading, aiChatLoading]);

  return (
    <div
      className={`${classes.chat_input_output_container} ${
        showCategories && classes.adjustHeight
      }`}
      ref={chatRef}
      style={style}
    >
      {messages?.map((messageData, idx) => {
        const isLastMessage = idx == messages.length - 1;

        return (
          <React.Fragment key={idx}>
            <div className={classes.chat_container_chat_box}>
              <div className={classes.avatar_logo}>
                <img
                  src="/assets/images/profile-avatar.png"
                  alt="logo"
                  className={classes.avatar}
                />
              </div>
              <div className={classes.chat_container_chat}>
                <p className={classes.chat_container_chat_text}>
                  {JSON.parse(messageData?.request)}
                </p>
              </div>
            </div>

            <div className={classes.chat_container_chat_box}>
              <div className={classes.avatar_logo}>
                <img
                  src="/assets/images/ai-logo.png"
                  alt="logo"
                  className={classes.avatar}
                />
              </div>
              <div className={classes.chat_container_chat}>
                {isLastMessage && aiChatLoading ? (
                  <p className={classes.chat_container_chat_text}>
                    <DotLoader />
                  </p>
                ) : (
                  <>
                    <p
                      className={classes.chat_container_chat_text}
                      dangerouslySetInnerHTML={{
                        __html: JSON.parse(messageData?.response)
                          .replace(/\n/g, "<br>")
                          .replace(
                            "ERROR-006e20bb3f8fb01e8aad18614b87f647:",
                            ""
                          )
                          .replace("ERROR-006e20bb3f8fb01e8aad18614b87f647", "")
                          .replace(/###\s*([^<>\n]+)/g, "<b>$1</b>")
                          .replace(
                            /\*\*(.*?)\*\*/g,
                            "<b style='font-weight:500'>$1</b>"
                          )
                          .replace(
                            /```(.*?)```/gs,
                            "<div style='background-color: rgba(0,0,0,1); color: white; padding: 5px;overflow:auto;border-radius:4px;padding:10px;'>$1</div>"
                          ),
                      }}
                    ></p>
                  </>
                )}
              </div>
            </div>
          </React.Fragment>
        );
      })}

      {info && (
        <div className={classes.info_container}>
          <i className={`pi pi-info-circle ${classes.info_icon}`} />
          <p className={classes.info}>
            You've reached your daily limit of 5 demo questions. Please return
            tomorrow for more.
          </p>
        </div>
      )}
    </div>
  );
};

export default ChatBox;
